html,
body {
  font-family: "Roboto Light 300", sans-serif;
}
.level-header {
  background: #2196f3; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f44336,
    #2196f3
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f44336,
    #2196f3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  padding: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.4);
}
.level-title {
  color: white;
  font-weight: 600;
}
.lives-icon {
  width: 25px;
}
.App {
  text-align: center;
}
.start-button {
  width: 80%;
  border-radius: 1rem !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.title {
  font-size: 49px;
  font-variant: small-caps;
  font-weight: 800;
}
.small-header {
  font-size: 14px;
  font-variant: small-caps;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-size: 100% 100vh;
}

/* .frame{
  height: 500px;;
  background-image: url('./images/frame.png');
  background-repeat: no-repeat; 
  background-size: 600px;
  background-position: center;
 
} */
.level-text {
  color: white;
  text-align: center;
  font-weight: 900;
}
.false-image {
  position: absolute;
  top: 35vh;
  left: 25vw;
  z-index: 1;
  width: 200px;
}
.img-level {
  height: 58vh;
}
.decrease-life {
  margin-left: 5px;
  margin-top: 1px;
  position: absolute;
  color: red;
  font-size: 22px;
}
.tag-line {
  font-size: 16px;
  color: black;
}
.democrat {
  color: red;
}
.republican {
  color: blue;
}
h1 span span {
  color: black;
  position: relative;
  bottom: -1em;
  opacity: 0;

  animation: move-text 0.75s forwards;
}
h1 span span:nth-child(2n) {
  color: black;
}
h1 span span:nth-child(3n) {
  color: black;
}
h1 span span:nth-child(4n) {
  color: black;
}
h1 span span:nth-child(5n) {
  color: black;
}

@keyframes move-text {
  0% {
    bottom: -0.2em;
    opacity: 1;
  }

  50% {
    bottom: 0.2em;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}
img.frame {
  border-image: url("./images/frame.png") 93 92 87 92 stretch stretch;
  border-color: #f4be52;
  border-style: inset;
  border-width: 60px;
  display: block;
  width: 40%;
  max-height: 450px;
  height: auto;
  background-color: #ffe;
  margin: 0 auto;
}
#menu-page {
  background-image: url("./images/background1.png");
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  height: 100vh;
}
@media all and (max-width: 500px) {
  img.frame {
    border-width: 20px;
    border-image: url("./images/frame.png") 93 92 87 92 stretch stretch;
    width: 96%;
    max-height: 370px;
    min-height: 350px;
  }
  body {
    /* background-image: linear-gradient(to top, #6a85b6 0%, #bac8e0 100%); */
    /* background:white;
      background-image: url('./images/background1.png');
      background-repeat: no-repeat;  */
    font-family: "Roboto", sans-serif !important;
  }
}
@media only screen and (min-width: 768px) {
  .desktop-image {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
  }
  .false-image {
    left: auto;
  }
}
/* img {
  border:solid 2px;
  border-bottom-color:#ffe;
  border-left-color:#eed;
  border-right-color:#eed;
  border-top-color:#ccb;
  max-height:100%;
  max-width:100%;
}
.false-image{
  position: absolute;
  left: 0;
  border: 0;
}
.frame {
  background-color:#ddc;
  border:solid 5vmin #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  box-shadow:0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
  box-sizing:border-box;
  display:inline-block;
  margin:10vh 10vw;
  /* height:80vh; 
  padding:8vmin;
  position:relative;
  text-align:center;
  &:before {
    border-radius:2px;
    bottom:-2vmin;
    box-shadow:0 2px 5px 0 rgba(0,0,0,.25) inset;
    content:"";
    left:-2vmin;
    position:absolute;
    right:-2vmin;
    top:-2vmin;
  }
  &:after {
    border-radius:2px;
    bottom:-2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
    content:"";
    left:-2.5vmin;
    position:absolute;
    right:-2.5vmin;
    top:-2.5vmin;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}
.sign-in-facebook {
  background: url("./images/democratic-logo.png") #f2f2f2;
  background-position: -9px -7px;
  background-repeat: no-repeat;
  background-size: 39px 43px;
  padding-left: 41px;
  color: #000;
}
.sign-in-facebook:hover {
  background: url("./images/democratic-logo.png") #e0e0e0;
  background-position: -9px -7px;
  background-repeat: no-repeat;
  background-size: 39px 43px;
  padding-left: 41px;
  color: #000;
}

.btn.bmd-btn-fab {
  width: 6.5rem;
  min-width: 6.5rem;
  height: 6.5rem;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.26);
}
