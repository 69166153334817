.email-button {
  display: block;
  border: 0px;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 19px;
  margin: 5px;
  width: calc(100% - 10px);
  overflow: hidden;
  padding: 0px 10px;
  user-select: none;
  height: 50px;
  background: rgb(59, 89, 152);
}
